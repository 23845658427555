<template>
  <div id="news">
    <div class="container">
      <div class="clearfix">
        <h2 class="text-center">{{ "news.label" | staticTranslation }}</h2>
      </div>
      <div class="row">
        <app-news-row v-for="post in news" v-bind:post="post" v-bind:key="post.label"></app-news-row>
      </div>
      <div class="text-center button-padding">
        <a href="/news">
          <div class="btn">{{ "news.next" | staticTranslation }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import newsRow from "./newsRow";
import axios from "@/api/axiom-instance";

export default {
  name: "news",
  components: {
    'app-news-row': newsRow
  },
  data() {
    return {
      news: []
    }
  },
  created() {
    axios
        .get("/article/visible?count=3")
        .then((success) => {
          this.news = success.data
        })
  }
}
</script>

<style scoped>
h1 {
  padding-top: 1em;
  font-size: 3.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

h2 {
  padding-top: 0.5em;
  font-weight: bold;
  font-size: 2.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

#news {
  background-color: #f3ba53;
  padding-bottom: 7em;
  padding-top: 3em;
}

.button-padding {
  padding-top: 3em;
}

.btn {
  border: solid 1px #152326;
  padding: 4px 12px 2px;
  border-radius: 0;
  font-size: 1.3rem;
  font-family: "Transcript Pro";
  background-color: #152326;
  color: #f3ba53;
}

.btn:hover {
  padding: 4px 12px 2px;
  font-family: "Transcript Pro";
  background-color: #152326;
}
</style>
