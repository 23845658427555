<template>
  <div class="container-new">
    <div v-if="this.slides" id="photoSlider">
      <a v-if="hasArrows" class="next" v-on:click="nextSlide">❯</a>
      <a v-if="hasArrows" class="prev" v-on:click="prevSlide">❮</a>
      <div class="position-relative">
        <a v-if="havelink" :href="links[counter]">
<!--          <div class="slider-button" type="button">-->
<!--            {{ "Navigation.entrance.tickets" | staticTranslation }}-->
<!--          </div>-->
        </a>
        <img class="img-fluid mx-auto d-block" :src="this.slides[this.counter]">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sliderPhoto",
  props: {
    slides: {},
    time: {},
    hasArrows: {},
    havelink: {
      defaults: false
    },
    links: {
      require: false
    }
  },
  data() {
    return {
      counter: 0,
    }
  },
  created() {
    setInterval(this.nextProgress, this.time);
  },
  methods: {
    nextSlide() {
      this.counter = (this.counter + 1) % this.slides.length;
    },
    prevSlide() {
      this.counter = (this.slides.length + this.counter - 1) % this.slides.length;
    },
    nextProgress() {
      this.nextSlide()
      this.progress = 0;
    },
  }
}
</script>

<style scoped>

@media screen and (max-width: 400px) {
  #photoSlider {
    padding-top: 4em;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #f3ba53;
    top: 50%;
    width: auto;
    padding: 5px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

@media screen and (max-width: 400px) {
  #photoSlider {
    padding-top: 4em;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #f3ba53;
    top: 55%;
    width: auto;
    padding: 5px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
  #photoSlider {
    padding-top: 4.5em;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #f3ba53;
    top: 60%;
    width: auto;
    padding: 5px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

@media screen and (min-width: 501px) {
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #f3ba53;
    top: 50%;
    width: auto;
    padding: 13px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 25px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  #photoSlider {
    padding-top: 4em;
  }
}

@media (min-width: 1025px) {
  #photoSlider {
    padding-top: 4.5em;
    overflow: hidden;
  }
}

a:hover {
  text-decoration: none;
  color: #eae7d6;
}

img {
  width: 100%;
}

.container-new {
  position: relative;
}

.slider-button {
  position: absolute;
  font-size: 2.5rem;
  font-family: "Transcript Pro";
  color: #f3ba53;
  background-color: black;
  padding: .1% 2.2%;
  text-align: end;
  right: 0;
  width: 26.55%;
  top: 56.5%;
}

@media screen and (min-width: 561px) and (max-width: 900px) {
  .slider-button {
    font-size: 1.2rem;
  }
}

@media screen and  (min-width: 250px) and (max-width: 560px) {
  .slider-button {
    font-size: .7rem;
    padding: .4rem 1rem;

  }
}
</style>
