<template>
  <div class="col-lg-4 post mt-4">
    <div v-if="this.post.title.en && isActiveEn || isActiveCs" class="box-shadow-3d">
      <div class="img-hover-zoom">
        <a :href="'/news/detail/' + this.post.id"><img class="img-fluid cropped-img" alt="photo"
                                                       :src="getImageUrl()"></a>
      </div>
      <div class="background">
        <a :href="'/news/detail/' + this.post.id"><h5 class="pl-4">{{ this.post.created | date }}</h5></a>
        <a :href="'/news/detail/' + this.post.id"><h4 class="pl-4 pr-2">{{ this.post.title | translation }}</h4></a>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config"

export default {
  name: "newsRow",
  props: ['post'],
  methods: {
    getImageUrl: function () {
      return config.API_URL + '/image/preview?type=article-main&name=' + this.post.id
    }
  },
  computed: {
    isActiveCs: function () {
      return !(localStorage.getItem('lang') === 'en')
    },
    isActiveEn: function () {
      return (localStorage.getItem('lang') === 'en')
    }
  }
}
</script>

<style scoped>
h5 {
  font-family: "Transcript Pro";
  font-size: 1em;
  padding-top: 1em;
  margin-bottom: 0;
}

h4 {
  font-size: 1.5em;
  font-family: "Transcript Pro";
  padding-bottom: .4em;
}

a {
  color: black;
  text-decoration: none;
  font-family: "Transcript Pro";
}

.cropped-img {
  height: 330px;
  width: 100%;
  object-fit: cover;
}

.box-shadow-3d {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.background {
  background-color: #e7e7e6;
  border-bottom: #eae7d6 2px solid;
}

.post {
  background-color: #ffa40000;
  color: black;
}

.img-hover-zoom {
  height: 330px;
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.3);
}

</style>
